<template>
  <div>
    <div>
      <img class="comimg" style="width: 100%" src="@/assets/solution/banner.png">
    </div>
    <div class="res" style="height: 712px">
      <img class="comimg" style="width: 100%" src="@/assets/solution/bg1.png">
      <div class="abs home-p-com">
        <div class="com-page-t1">运维信息黑洞</div>
      </div>
    </div>
    <div class="res" style="height: 830px">
      <img class="comimg" style="width: 100%" src="@/assets/solution/bg2.png">
      <div class="abs home-p-com">
        <div class="com-page-t1">企修通运维解决方案<br/>
          构建领先行业的运维标准
        </div>
      </div>
    </div>
    <div class="res" style="height: 626px">
      <img class="comimg" style="width: 100%" src="@/assets/solution/bg3.png">
    </div>
    <div class="res" style="height: 677px">
      <img class="comimg" style="width: 100%" src="@/assets/solution/bg4.png">
    </div>
    <div class="res" style="height: 630px">
      <img class="comimg" style="width: 100%" src="@/assets/solution/bg5.png">
    </div>
    <div class="res" style="height: 1150px">
      <img class="comimg" style="width: 100%" src="@/assets/solution/bg6.png">
      <div class="abs home-p-com" style="width: 1277px;">
        <div class="com-page-t1">企修通运维场景解决方案<br/>
          提供优质的运维服务
        </div>
        <div class="s-con-v">
          <div style="display: flex;height: 342px;margin:60px 39px 2px 38px">
            <div style="flex: 1">
              <div class="s-con-top">重庆某大型三甲医院</div>
              <div style="display: flex;margin-top: 50px">
                <img style="width: 215px;height: 214px" src="@/assets/solution/hospita.png">
                <div
                    style="font-size: 18px;font-weight: 400;color: #717477;line-height: 28px;margin: 0 28px;width: 348px;height: 214px;display: flex;flex-direction: column;justify-content: space-between">
                  <div><span style="color: #171717 ">医院概况：</span>医院总面积约33万方,是一所集预防、医疗、保健为一体的三级甲等医院
                  </div>
                  <div><span style="color: #171717 "> 医院规模：</span>三甲医院</div>
                  <div><span style="color: #171717 ">科室数量：</span>40+</div>
                  <div><span style="color: #171717 "> 医护人员：</span>1500+</div>
                </div>
              </div>
            </div>
            <img style="width: 575px;height: 324px" src="@/assets/solution/图.png">
          </div>
          <span class="s-question">面临的问题：</span>
          <div style="margin: 15px 35px 0 33px;">
            <div v-for="(item,k) in sevenArr" :key="k" class="s-q-bg">
              <img style="float:left;width: 30px;height: 25px;margin: 20px" src="@/assets/solution/ofs-i-chose-dui.png">
              <div style="display: flex;margin: 20px 20px 20px 70px">{{item}}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="res" style="height: 754px">
      <img class="comimg" style="width: 100%" src="@/assets/solution/bg7.png">
      <div class="abs home-p-com">
        <div class="com-page-t1" style="margin-bottom: 80px">解决方案</div>
        <div class="cflex" style="height: 429px;">
          <div v-for="(item,index) in eighthArr" :key="'e'+index" class="ee-bg">
            <div class="show-right">
              <span class="e-right">0{{index+1}}</span>
            </div>
            <img style="width: 62px;height: 62px;margin-left: 18px" :src="item.icon">
            <div class="e-tit">{{item.tit}}</div>
            <div class="e-des">{{item.des}}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="res" style="height: 894px">
      <img class="comimg" style="width: 100%" src="@/assets/solution/bg8.png">
      <div class="abs home-p-com">
        <div class="com-page-t1" style="margin-bottom: 80px">来自客户的评价</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "index",
  data () {
    return {
      sevenArr: [
        '科室繁杂，数目较多，护士基数大，设备位置零散分布，数量和类型较多，维修人员无法精准定位；',
        '报修方式依旧一电话报修为主，无法实现统一平台调度，报修进度跟踪难；',
        '设备管理工作中的信息，如设备基础信息、故障记录、维修记录、配件使用情况，记录内容没有规定的格式，信息零散，信息查询起来十分困难；',
        '设备管理工作中的信息，如设备基础信息、故障记录、维修记录、配件使用情况，记录内容没有规定的格式，信息零散，信息查询起来十分困难；',
      ],
      eighthArr: [
        {
          icon: require('@/assets/solution/ofs-i-yunweixitong.png'),
          tit: '全新接入企修通运维系统',
          des: '实现设施、设备的“一机一码”管理，每台设备精准定位，极大提升维修员上门效率。'
        },
        {
          icon: require('@/assets/solution/ofs-i-diaodu.png'),
          tit: '工单统一调度管理',
          des: '所有报修请求统一生成工单，智能化分派给维修人员，维修进度实时同步，所有保修进展一目了然。'
        },
        {
          icon: require('@/assets/solution/ofs-i-baobiao.png'),
          tit: '丰富的统计报表',
          des: '丰富的统计报表，分析设备的维保记录、配件耗材更换记录、品牌品类的损耗率，为企业的管理和采购提供数据支撑。 '
        },
        {
          icon: require('@/assets/solution/ofs-i-SaaSbushu.png'),
          tit: 'SaaS化部署',
          des: 'SaaS模式，界面友好，注册即用。零维护成本，很大程度缓解医院的财力和人力上的压力。'
        },
      ]
    }
  },
  mounted () {
    this.$parent.gotoTop()
  },
}
</script>

<style scoped>
  .s-con-v {
    margin-top: 80px;
    height: 780px;
    background: linear-gradient(360deg, rgba(225, 233, 246, 0.5) 0%, rgba(250, 252, 255, 0.85) 100%);
    border-radius: 18px;
    border: 1px solid #FFFFFF;
  }

  .s-con-top {
    font-size: 36px;
    font-weight: 600;
    color: #1F5FFF;
    line-height: 48px;
  }

  .s-question {
    width: 172px;
    height: 52px;
    line-height: 52px;
    background: rgba(31, 95, 255, 0.11);
    border-radius: 4px 4px 4px 4px;
    font-weight: 600;
    color: #1F5FFF;
    font-size: 22px;
    padding: 11px 20px;
    margin-left: 39px;
  }

  .s-q-bg {
    display: inline-block;
    background: rgba(255, 255, 255, 0.63);
    border-radius: 4px 4px 4px 4px;
    font-size: 20px;
    font-weight: 400;
    color: #171717;
    vertical-align: top;
    width: calc(50% - 12px - 2px);
    margin: 5px;
    /* border: 1px solid #FFFFFF; */
    /* box-sizing: border-box; */
    border: 1px solid #FFFFFF;
  }

  .s-q-bg:hover {
    color: white;
    background: linear-gradient(180deg, #4189FF 0%, #1F5FFF 100%);
    border: 1px solid #FFFFFF;
  }

  .ee-bg {
    background: linear-gradient(180deg, #FFFFFF 0%, #EBF2FF 100%);
    border-radius: 18px;
    height: 429px;
    width: 280px;
    border: 2px solid #FFFFFF;
    margin: 0 13px;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
  }

  .ee-bg:hover {
    box-shadow: 10px 10px 10px 1px rgba(31, 95, 255, 0.1);
    border: 1px solid rgba(65, 137, 255, 0.22);
    border-bottom: 4px solid #1F5FFF;
    border-radius: 18px 18px 4px 4px;
    /* box-sizing: border-box; */
  }

  .show-right {
    opacity: 0;
    text-align: right;
  }

  .ee-bg:hover .show-right {
    opacity: 1;
  }

  .e-right {
    width: 70px;
    height: 42px;
    background: linear-gradient(180deg, #4189FF 0%, #1F5FFF 100%);
    border-radius: 0px 18px 0px 20px;
    opacity: 1;
    color: white;
    font-size: 24px;
    font-weight: 400;
    line-height: 42px;
    padding: 4px 18px 4px 22px;
  }

  .e-tit {
    margin: 23px 40px 27px 42px;
    height: 65px;
    position: relative;
    font-size: 25px;
    font-weight: 600;
    color: #292D33;
    line-height: 29px;
  }

  .e-tit::before {
    position: absolute;
    content: '';
    left: -15px;
    top: 3px;
    width: 5px;
    height: 22px;
    background: #1F5FFF;
  }

  .e-des {
    font-weight: 400;
    color: #606266;
    line-height: 24px;
    font-size: 18px;
    margin: 0 39px 0 27px;
  }
</style>
